import React from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineMessage } from 'react-icons/md';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar, AiOutlineShopping } from 'react-icons/ai';
import { useStateContext } from '../context/StateContext';
import { urlFor } from '../sanity/sanityClient';
import 'react-toastify/dist/ReactToastify.css';

const ProductDisplay = ({ products, handleImageSelect, handleAddToCart }) => {
  const { decQty, incQty, qty } = useStateContext();

  const handleGetDirections = (productLocation) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLatitude = position.coords.latitude;
          const userLongitude = position.coords.longitude;
          const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${productLocation.lat},${productLocation.lng}&travelmode=driving`;
          window.open(directionsUrl, "_blank");
        },
        (error) => {
          console.error("Error fetching user location:", error);
          alert("Unable to fetch your location. Please allow location access.");
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  return (
    <div className="itemList" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {products.map((product) => (
        <div
          key={product._id}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'transform 0.3s',
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '8px',
          }}
        >
          {product.user && (
            <Link to={`/chat/${product.user}`} style={{ color: 'blue', textDecoration: 'none', marginBottom: '5px' }}>
              <MdOutlineMessage /> Message Seller
            </Link>
          )}
          <div
            className="image-container"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={urlFor(product.image[product.currentImageIndex])}
              alt={product.name}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
              className="product-detail-image"
            />
            <div
              className="small-images-container"
              style={{
                width: '100%',
                overflowX: 'auto', // Enable horizontal scrolling
                whiteSpace: 'nowrap', // Ensure thumbnails stay in a single row
                margin: '5px 0',
              }}
            >
              <div
                className="thumbnail-container"
                style={{
                  display: 'inline-flex', // Use inline-flex for horizontal layout
                  gap: '5px', // Add spacing between thumbnails
                }}
              >
                {product.image.map((img, index) => (
                  <img
                    key={index}
                    src={urlFor(img)}
                    alt={`Thumbnail ${index + 1}`}
                    className={`thumbnail ${index === product.currentImageIndex ? 'active' : ''}`}
                    onClick={() => handleImageSelect(product._id, index)}
                    style={{
                      width: '60px', // Set a fixed width for thumbnails
                      height: '60px', // Set a fixed height for thumbnails
                      objectFit: 'cover',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                  />
                ))}
              </div>
            </div>
            <h4 style={{ textAlign: 'start', margin: '5px 0' }}>{product.name}</h4>
          </div>
          <span style={{ display: 'block', margin: '5px 0' }}>
            <p style={{ color: 'red', margin: '0' }}>TZS:<strong>{product.price.toLocaleString()}/=</strong></p>
            <p style={{ margin: '5px 0' }}>{product.details}</p>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
              <div className="reviews" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiOutlineStar />
                <p>(4)</p>
              </div>
              <div className="quantity" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <span style={{ cursor: 'pointer' }} onClick={decQty}><AiOutlineMinus /></span>
                  <span>{qty}</span>
                  <span style={{ cursor: 'pointer' }} onClick={incQty}><AiOutlinePlus /></span>
                </span>
              </div>
            </div>
            <div className="buttons" style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
              {product.geolocation && (
                <button
                  className="add-to-cart"
                  style={{
                    padding: '8px 12px',
                    backgroundColor: '#007BFF',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={() => handleGetDirections(product.geolocation)}
                >
                  Get Directions
                </button>
              )}
              <button
                type="button"
                className="add-to-cart"
                onClick={() => handleAddToCart(product)}
                style={{
                  padding: '8px 12px',
                  backgroundColor: '#28a745',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  borderRadius: '4px',
                }}
              >
                Add to <AiOutlineShopping />
              </button>
            </div>
          </span>
        </div>
      ))}
    </div>
  );
};

export default ProductDisplay;