import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { GoArrowLeft } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import styled from "styled-components";
import ChatInput from "./ChatInput";
import Logout from "./Logout";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import messageSound from "../assets/sounds/message.mp3";
import { client, urlFor } from '../sanity/sanityClient';
import { sendmessageroute, recievemessageroute, deletemessageroute } from "../utils/APIRoutes";
import SurpriseModal from "./SurpriseModal";
import "../components/App.css";
import CryptoJS from 'crypto-js';

// Floating Date Badge Component
const FloatingDateBadge = ({ date }) => {
  return (
    <div
      style={{
        position: "sticky",
        top: "10px",
        zIndex: 1,
        backgroundColor: "#f0f0f0",
        padding: "5px 10px",
        borderRadius: "10px",
        margin: "10px auto",
        textAlign: "center",
        width: "fit-content",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      {date}
    </div>
  );
};

export default function ChatContainer({ currentChat, fetchTraderProducts, socket, selectedProduct, productDeselect, handleBack, fetchMessaged }) {
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef();
  const [categories, setCategories] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [backMessage, setBackMessage] = useState('');
  const [typingUser, setTypingUser] = useState(null); // User who is typing
  const typingTimeoutRef = useRef(null);

  // Encryption key (should be securely shared between users)
  const encryptMessage = (message) => {
    return CryptoJS.AES.encrypt(message, process.env.REACT_APP_SECRET_KEY).toString();
  };

  const decryptMessage = (encryptedMessage) => {
    const bytes = CryptoJS.AES.decrypt(encryptedMessage, process.env.REACT_APP_SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  // Group messages by date
  const groupMessagesByDate = (messages) => {
    const groupedMessages = [];
    let currentDate = null;

    messages.forEach((message) => {
      const messageDate = message.date || new Date().toLocaleDateString();
      if (messageDate !== currentDate) {
        groupedMessages.push({ type: "date", date: messageDate });
        currentDate = messageDate;
      }
      groupedMessages.push({ type: "message", ...message });
    });

    return groupedMessages;
  };

  useEffect(() => {
    async function fetchMessages() {
      const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
      const response = await axios.post(recievemessageroute, {
        from: data._id,
        to: currentChat._id,
      });
      const decryptedMessages = response.data.map(msg => ({
        ...msg,
        message: decryptMessage(msg.message),
        date: new Date(msg.timestamp).toLocaleDateString(), // Add date property
      }));
      setMessages(decryptedMessages);
    }

    if (currentChat) {
      fetchMessages();
    }
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
    const encryptedMsg = encryptMessage(msg);

    socket.current.emit("send-msg", {
      to: currentChat._id,
      from: data._id,
      msg: encryptedMsg,
      sender: data.username,
      receiver: currentChat.email,
    });

    await axios.post(sendmessageroute, {
      from: data._id,
      to: currentChat._id,
      message: encryptedMsg,
    });

    const msgs = [...messages];
    const stamp = new Date().toLocaleString('default', {
      hour: 'numeric',
      minute: 'numeric',
    });
    const date = new Date().toLocaleDateString(); // Add date property
    msgs.push({ fromSelf: true, message: msg, stamp, date });
    setMessages(msgs);
    fetchMessaged();
  };

  const handleDeleteMSG = async () => {
    const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
    try {
      const response = await axios.post(deletemessageroute, {
        from: data._id,
        to: currentChat._id,
      });
      setBackMessage(response.data.msg);
      setMessages([]);
      fetchMessaged();
    } catch (error) {
      console.error('Error deleting messages:', error);
    }
  };

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", (encryptedMsg) => {
        const decryptedMsg = decryptMessage(encryptedMsg);
        const stamp = new Date().toLocaleString('default', {
          hour: 'numeric',
          minute: 'numeric',
        });
        const date = new Date().toLocaleDateString(); // Add date property
        setArrivalMessage({ fromSelf: false, message: decryptedMsg, stamp, date });
        fetchMessaged();
        if (!document.hasFocus() || document.hasFocus()) {
          const sound = new Audio(messageSound);
          sound.play();
        }
      });

      socket.current.on("user-typing", (data) => {
        if (data.from === currentChat._id) {
          if (data.isTyping) {
            setTypingUser(data.from);
          } else {
            setTypingUser(null);
          }
        }
      });
    }
  }, [socket, currentChat._id, fetchMessaged]);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleKeyDown = async () => {
    const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
    // Emit "typing" when the user starts typing
    socket.current.emit("typing", { from: data._id, to: currentChat._id });

    // Clear any existing timeout to stop typing
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
  };

  const handleKeyUp = async () => {
    const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
    // Start a timeout to emit "stop-typing" if no key is pressed for 1 second
    typingTimeoutRef.current = setTimeout(() => {
      socket.current.emit("stop-typing", { from: data._id, to: currentChat._id });
    }, 1000);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const query = `
          *[_type in [ "clothingandfashion", "electronics", "arts", "fashion", "salon", "grocery", "beauty", "crops",
         "toys", "sports", "kitchen", "hotels", "homeandfurniture", "hardware", "books", "estate", "foodandbeverages",
          "homeandfurnitures", "vehicles", "jewelryandaccessories", "medical"]] {
            _id,
            _type,
            slug,
            image
          }
        `;
        const result = await client.fetch(query);
        setCategories(result);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Container>
      <div className="chat-header">
        <div className="user-details">
          <div className="avatar" onClick={() => fetchTraderProducts(currentChat._id)}>
            {currentChat.avatarImage.startsWith("https://") || currentChat.avatarImage.includes("https") ? (
              <img src={currentChat.avatarImage} alt={`${currentChat.username}'s avatar`} />
            ) : (
              <img src={`data:image/svg+xml;base64,${currentChat.avatarImage}`} alt={`${currentChat.username}'s avatar`} />
            )}
          </div>
          <div className="username">
            <h3>{currentChat.username}</h3>
            {typingUser === currentChat._id && (
              <p style={{ fontSize: '8px', color: 'green', margin: "0" }}>is typing...</p>
            )}
          </div>
        </div>
        <Logout />
      </div>
      <br />
      {messages.length < 1 && backMessage && (
        <div>
          <p>{backMessage}</p>
        </div>
      )}
      <div className="chat-messages">
        {messages.length > 0 &&
          groupMessagesByDate(messages).map((item, index) => {
            if (item.type === "date") {
              return <FloatingDateBadge key={`date-${index}`} date={item.date} />;
            }

            const message = item;
            const timestamp = message.timestamp || '';

            if (message.message.includes('image-')) {
              const matchedCategory = categories.find((category) => category.image[0].asset._ref === message.message);
              return (
                <div ref={scrollRef} key={uuidv4()}>
                  <div className={`message ${message.fromSelf ? "sended" : "recieved"}`}>
                    <div className="content">
                      {matchedCategory && (
                        <Link to={`/category/${matchedCategory.slug.current}`}>
                          <img className="option" src={urlFor(message.message)} alt="messed up!" width={100} />
                        </Link>
                      )}
                      <span className="timestamp">
                        {timestamp
                          ? new Date(timestamp).toLocaleTimeString('default', { hour: 'numeric', minute: 'numeric' })
                          : message.stamp}
                      </span>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div ref={scrollRef} key={uuidv4()}>
                  <div className={`message ${message.fromSelf ? "sended" : "recieved"}`}>
                    <div className="content">
                      <p style={{ margin: '0' }}>{message.message}</p>
                      <span className="timestamp">
                        {timestamp
                          ? new Date(timestamp).toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
                          : message.stamp}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
      <div>
        <SurpriseModal
          isOpen={isModalOpen}
          socket={socket}
          currentChat={currentChat._id}
          user={currentChat.username}
          onRequestClose={() => setIsModalOpen(false)}
          selectedProduct={selectedProduct}
          productDeselect={productDeselect}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{ marginLeft: '20px' }} onClick={handleBack}>
          <GoArrowLeft />
        </h2>
        <button
          style={{
            width: '30px',
            height: '30',
            padding: '0.3rem 2rem',
            borderRadius: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff34',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => setIsModalOpen(true)}
        >
          🎁 Gift!
        </button>
        <button
          style={{
            position: 'relative',
            marginRight: '30px',
            borderRadius: '0.5rem',
            color: 'black',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={handleDeleteMSG}
        >
          <MdDeleteForever />
        </button>
      </div>
      <ChatInput
        handleSendMsg={handleSendMsg}
        selectedProduct={selectedProduct}
        productDeselect={productDeselect}
        handleKeyUp={handleKeyUp}
        handleKeyDown={handleKeyDown}
      />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-column: 10% 40% 50%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 50% 35%;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    .user-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: black;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #f4e3c1;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 100%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: black;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 90%;
        }
      }
      
      .timestamp {
        font-size: 0.8rem;
        margin-left:0.1rem;
        color: #777;
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: rgb(194, 243, 194);
      }

    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #f5ccc2;
      }
    }
  }
`;