import React, { useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import axios from "axios";
import {client, urlFor } from "../sanity/sanityClient";
import CategoriesDisplayTwo from './CategoriesDisplayTwo';
import Cart from './Cart';
import { useStateContext } from '../context/StateContext';
import { AiOutlineShopping } from 'react-icons/ai';
import { CgAdd } from 'react-icons/cg';
import { FiMenu } from 'react-icons/fi';
import { GoSearch, GoArrowLeft } from 'react-icons/go';
import writer from '../assets/writer.svg';
import logo192 from './logo192.jpg';
import Amazon from './Amazon.png';
import Loader from './Loader';
import SearchForm from './SearchForm';
import UploadForm from "./UploadForm";
import { Sendrequest, onMessageListener } from '../firebase';
import {
  allowedusers, postInteractions, retrieveInteractions
} from "../utils/APIRoutes";

import Aliexpress from './Aliexpress.png';
import './App.css';

const Categories = ({categories, isLoading, changeCategory, currentUser, showCntcts, handleNearbyClick,
         fetchTraderProducts, nearBy, isTrader, traderProducts, message}) => {
  const navigate = useNavigate();
  const { showCart, setShowCart, totalQuantities } = useStateContext();
  const [name, setName] = useState('BridgenApp');
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allowedUsers, setAllowedUsers]=useState([]);
  const [isBrand, setIsBrand]=useState(false);
  const [products, setProducts] = useState([]);
  

  // Fetch allowed users
  useEffect(()=>{
      const fetchAllowedUsers = async () => {
          try {
            const {data} = await axios.get(allowedusers);
            setAllowedUsers(data.map(user => user._id));
          } catch (error) {
            console.error("Error fetching requested users:", error);
          }
        };
        fetchAllowedUsers();
  }, [])
  
   const isUserAllowed = currentUser?._id && allowedUsers.includes(currentUser._id);

   const fetchRecommendations = async (userId) => {
  try {
    let recommendedProducts = [];

    if (userId) {
      // Fetch recommendations for registered users (as before)
      const response = await fetch(`${retrieveInteractions}/${userId}`);
      const { productIds, categories } = await response.json();

      if (categories.length === 0) return [];

      const allProducts = await client.fetch(
        `*[_type in $categories && !(_id in $productIds)]`,
        { categories, productIds }
      );

      recommendedProducts = allProducts.sort(() => Math.random() - 0.5).slice(0, 10); // Randomly sample 10 products
    } else {
      // Fetch recommendations for unregistered users
      const sessionKey = "anonymousInteractions";
      const interactions = JSON.parse(sessionStorage.getItem(sessionKey)) || [];

      if (interactions.length === 0) {
        // Fallback: Fetch popular products
        recommendedProducts = await client.fetch(
          `*[_type in $categories] | order(popularity desc) [0...10]`,
          { categories: ["electronics", "clothingandfashion", "beauty"] } // Example categories
        );
      } else {
        // Fetch products similar to recently viewed items
        const viewedProductIds = interactions.map((i) => i.productId);
        const categories = [...new Set(interactions.map((i) => i.category))];

        recommendedProducts = await client.fetch(
          `*[_type in $categories && !(_id in $viewedProductIds)]`,
          { categories, viewedProductIds }
        );

        // Randomly sample 10 products
        recommendedProducts = recommendedProducts.sort(() => Math.random() - 0.5).slice(0, 10);
      }
    }

    return recommendedProducts;
  } catch (error) {
    console.error("Error fetching recommendations:", error);
    return [];
  }
};


 useEffect(() => {
  const userId = currentUser?._id || null; // Use null for unregistered users
  fetchRecommendations(userId).then(setProducts);
}, [currentUser]);


// Function to log interactions for unregistered users
const logInteraction = (productId, category, interactionType) => {
  const sessionKey = "anonymousInteractions";
  let interactions = JSON.parse(sessionStorage.getItem(sessionKey)) || [];

  // Add new interaction
  interactions.push({ productId, category, interactionType, timestamp: new Date() });

  // Save updated interactions to session storage
  sessionStorage.setItem(sessionKey, JSON.stringify(interactions));
};

// Example: Log a product view
const handleProductView = async (productId, category) => {
  if (!currentUser) {
    logInteraction(productId, category,"view");
  } else {
    // Log interaction for registered users (as before)
    await axios.post(postInteractions, {
      userId: currentUser._id,
      productId,
      interactionType: "view",
      category
    });
  }
};

useEffect(() => {
  if (products.length > 0) {
    // Add a class to trigger animation
    const productElements = document.querySelectorAll(".recommended-product");
    productElements.forEach((element, index) => {
      setTimeout(() => {
        element.style.opacity = 1;
        element.style.transform = "translateY(0)";
      }, index * 100); // Staggered delay
    });
  }
}, [products]);

   const handleProtectedClick = (path) => {
    if (!currentUser) {
      navigate("/login");
    } else {
      navigate(path);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        await Sendrequest();
        const payload = await onMessageListener();
        console.log({
          title: payload?.title,
          body: payload?.body,
        });
      } catch (err) {
        console.error('Failed:', err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const filteredResults = categories.filter((category) =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchResults(filteredResults);
      setSearching(true);
    } else {
      setSearchResults([]);
      setSearching(false);
    }
  }, [categories, searchQuery]);

  const goBrand=()=>{
    setIsBrand(!isBrand)
  }
   
   const handleReload=()=>{
    window.location.reload();
  }
  

  return (
    <div className="app-container" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <div className="market" style={{ width: '100%', backgroundColor: '#00000076' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', textAlign: 'right', top: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
          <div style={{ color: 'white', height: '20px' }} onClick={showCntcts}><FiMenu /></div>
                <span>
            <button
              onClick={() =>
                handleProtectedClick(
                  isUserAllowed
                    ? `/addproduct/${currentUser?._id}`
                    : `/userrequests/${currentUser?._id}`
                )
              }
              style={{
                fontSize:'14px',
                borderRadius: "5px",
                color: "black",
                textDecoration: "none",
                cursor: "pointer",
                height:'20px',
              }}
            >
              <CgAdd />Post
            </button>
          </span>

          {currentUser?._id==="657d60c2e11554ed0ddbdc31" && <Link to={`/userrequests/${currentUser._id}`} style={{ color: 'black', textDecoration: 'none' }}>
                    Verify
                  </Link>}
          <button onClick={goBrand} style={{ height: '20px' }}>{isBrand?(< GoArrowLeft/>):("Brands")}</button>
          {nearBy?( <button onClick={handleReload} style={{ height: '20px', textDecoration: 'none' }}><GoArrowLeft /></button>):(<button onClick={handleNearbyClick}  style={{ height: '20px' }}>Nearby</button>)}
          <Link to={`/orders/${currentUser?._id}`} style={{ color: 'white', textDecoration: 'none' }}>Orders</Link>
          <div className="installContainer">
            <button id="butInstall" style={{ display: 'none' }}>Install App</button>
          </div>
          <button type="button" className="cart-icon" onClick={() => setShowCart(true)}>
            <AiOutlineShopping />
            <span className="cart-item-qty">{totalQuantities}</span>
          </button>
          <div className={`modal ${showCart ? 'active' : ''}`}><Cart /></div>
        </div>
        <br />
        <div className="search-form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', marginLeft: '8%', borderRadius: '10px', backgroundColor: '#f0f0f0', border: 'solid' }}>
          
          <div><GoSearch /></div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            required
            placeholder={`Search ${name} items`}
            style={{ width: '80%', height: '20px', borderRadius: '5px', border: 'none', outline: 'none' }}
          />
        </div>
        <br />
        {searching && <SearchForm searchResults={searchResults} />}
      </div>

      {isBrand ? (
          <UploadForm fetchTraderProducts={fetchTraderProducts} isTrader={isTrader} traderProducts={traderProducts} currentUser={currentUser} />
        ) : (
          <div className="search-results">
            {isLoading && categories.length <= 0 ? (
              <Loader />
            ) : !isLoading && categories.length <= 0 ? (
              <div className='displayCenter' style={{ textAlign: 'center' }}>
                <img src={writer} width={300} height={300} alt="no-internet" />
                <h5>No Products</h5>
                <p>Check another category or trader</p>
              </div>
            ) : (
              <div style={{ flexGrow: 1, overflowY: "auto" }}>
              {products.length>0 && <div className="recommendations">
              <div className="recommended-products">
                {products.map((product) => (
                  <div key={product._id} className="recommended-product">
                    <Link to={`/category/${product.slug.current}`}>
                      <img src={urlFor(product.image[0])} alt={product.name} width={100} />
                    </Link>
                    <p>{product.name}</p><br/>
                    <p>Tzs: {product.price?.toLocaleString()}/=</p>
                  </div>
                ))}
              </div>
            </div>}
                {/* Render the sign-in link when message is "loadingContacts" */}
              {message === "unregistered" ? (
                <p style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: "0",
                  borderRadius: '1px',
                  background: 'linear-gradient(to bottom right, #0056b3, pink)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  color: 'transparent',
                  fontWeight: 'bold',
                  justifyContent: 'center'
                }}>
                  Kindly 
                  <Link
                    to="/login"
                    style={{
                      color: 'blue',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      marginLeft:"2px",
                      marginRight:'3px'
                    }}
                  >
                    Sign in
                  </Link>
                   to enjoy all services!
                </p>
              ) : message ? (
                <p style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: "0",
                  borderRadius: '1px',
                  background: 'linear-gradient(to bottom right, #0056b3, pink)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  color: 'transparent',
                  fontWeight: 'bold',
                  justifyContent: 'center'
                }}>
                  {message}
                </p>
              ) : null}
                 
            <CategoriesDisplayTwo categories={categories} handleProductView={handleProductView}/>
               
              </div>
            )}
          </div>
        )}
      
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={logo192} style={{ width: 30, height: 20, padding: 10, borderRadius: 10, marginTop: 0 }} alt="BridgeApp_logo" onClick={() => setName('BridgenApp')} />
        <img src={Amazon} style={{ width: 40, height: 20, padding: 10, borderRadius: 10, marginTop: 0 }} alt="Amazon_logo" onClick={() => setName('(Not enabled)')} />
        <img src={Aliexpress} style={{ width: 40, height: 20, padding: 10, borderRadius: 10, margin: 0 }} alt='Aliexpress_logo' onClick={() => setName('(Not yet)')} />
      </div>
      <div className="footer" style={{ textAlign: 'center' }}>
        <p>For enquiry <Link to="/chat/657d60c2e11554ed0ddbdc31">write to</Link> Customer Support</p>
        <p style={{fontSize:'12px', margin:'0px'}}>Copyright © 2025 BridgenApp.</p>
      </div>
    </div>
  );
};

export default Categories;
