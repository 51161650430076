import React from 'react';
import { Link } from 'react-router-dom';
import { urlFor } from '../sanity/sanityClient';
import 'react-toastify/dist/ReactToastify.css';

const MobileTraderbrand = ({ products, deleteProduct, userbrandId }) => {
 

  const handleGetDirections = (productLocation) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLatitude = position.coords.latitude;
          const userLongitude = position.coords.longitude;
          const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${productLocation.lat},${productLocation.lng}&travelmode=driving`;
          window.open(directionsUrl, "_blank");
        },
        (error) => {
          console.error("Error fetching user location:", error);
          alert("Unable to fetch your location. Please allow location access.");
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  return (
    <div className="itemList" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {products.map((product) => (
        <div
          key={product._id}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'transform 0.3s',
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '8px',
          }}
        >
          <div
            className="image-container"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
          {product.image && (            
            <img
              src={urlFor(product.image[0])}
              alt={product.name}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
              className="product-detail-image"
            />
            )}
            <div
              className="small-images-container"
              style={{
                width: '100%',
                overflowX: 'auto', // Enable horizontal scrolling
                whiteSpace: 'nowrap', // Ensure thumbnails stay in a single row
                margin: '5px 0',
              }}
            >
            </div>
            <h4 style={{ textAlign: 'start', margin: '5px 0' }}>{product.name}</h4>
          </div>
          <span style={{ display: 'block', margin: '5px 0' }}>
            <p style={{ color: 'red', margin: '0' }}>TZS:<strong>{product.price.toLocaleString()}/=</strong></p>
            <p style={{ margin: '5px 0' }}>{product.details}</p>
            <div className="buttons" style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
              {product.geolocation && (
                <button
                  className="add-to-cart"
                  style={{
                    padding: '8px 12px',
                    backgroundColor: '#007BFF',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={() => handleGetDirections(product.geolocation)}
                >
                  Get Directions
                </button>
              )}
              {userbrandId === product.user ?(<button
                type="button"
                className="add-to-cart"
                onClick={() => deleteProduct(product._id)}
                style={{
                  padding: '8px 12px',
                  backgroundColor: '#28a745',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  borderRadius: '4px',
                }}
              >
                Delete
              </button>):(<Link to={`/chat/${product.user}`}
                style={{
                  padding: '8px 12px',
                  backgroundColor: '#28a745',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  textDecoration: 'none'
                }}
              >Message</Link>)}
              
            </div>
          </span>
        </div>
      ))}
    </div>
  );
};

export default MobileTraderbrand;