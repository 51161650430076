import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { orderslist, updateOrderProcessed, updateOrderShipped } from "../utils/APIRoutes"; // Add new API routes for updating order status
import { client, urlFor } from "../sanity/sanityClient";
import './OrdersList.css';

const OrdersList = () => {
  const { userId } = useParams();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sanityProducts, setSanityProducts] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(orderslist);
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const query = 
          `*[_type in ["grocery", "beauty", "hotels", "estate", "clothingandfashion", "kitchen", "crops", "electronics", "jewelryandaccessories", "salon", "foodandbeverages", "sports",  "arts", "medical", "vehicles", "fashion"]] {
            _id,
            image
          }`;
        const result = await client.fetch(query);
        setSanityProducts(result);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const getProductImage = (imageKey) => {
    if (!imageKey) return null;

    const product = sanityProducts.find((p) =>
      p.image?.some((img) => img._key === imageKey)
    );

    if (product) {
      const matchedImage = product.image.find((img) => img._key === imageKey);
      return matchedImage ? urlFor(matchedImage).url() : null;
    }

    return null;
  };

  // Function to handle updating order status to processed
  const handleProcessed = async (orderId) => {
    try {
      const response = await fetch(`${updateOrderProcessed}/${orderId}`, {
        method: 'PUT',
      });
      if (response.ok) {
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order._id === orderId ? { ...order, status: 'processed' } : order
          )
        );
      }
    } catch (error) {
      console.error("Error updating order processed status:", error);
    }
  };

  // Function to handle updating order status to shipped
  const handleShipped = async (orderId) => {
    try {
      const response = await fetch(`${updateOrderShipped}/${orderId}`, {
        method: 'PUT',
      });
      if (response.ok) {
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order._id === orderId ? { ...order, status: 'shipped' } : order
          )
        );
      }
    } catch (error) {
      console.error("Error updating order shipped status:", error);
    }
  };

  // Filter orders based on userId
  const filteredOrders = userId === "657d60c2e11554ed0ddbdc31"
    ? orders // Show all orders for the special userId
    : orders.filter(order => 
        order.cartItems.some(item => item.user === userId) // Show only orders with at least one cartItem matching the userId
      );

  return (
    <div className="orders-container">
      <h2 className="heading">📦 Submitted Orders</h2>

      {loading ? (
        <p className="loading">Loading orders...</p>
      ) : filteredOrders.length === 0 ? (
        <p className="no-orders">No orders found.</p>
      ) : (
        <div className="orders-grid">
          {filteredOrders.map((order) => {
            // Filter cartItems based on userId (only for non-special userId)
            const filteredCartItems = userId === "657d60c2e11554ed0ddbdc31"
              ? order.cartItems // Show all cartItems for the special userId
              : order.cartItems.filter(item => item.user === userId); // Show only cartItems matching the userId

            return (
              <div
                key={order._id}
                className={`order-card ${order.status === 'processed' ? 'processed' : ''} ${order.status === 'shipped' ? 'shipped' : ''}`}
              >
                <h3 className="order-title">Order Details</h3>
                <div className="order-info">
                  <p>👤 <strong>Buyer:</strong> {order.fullName}</p>
                  {userId === "657d60c2e11554ed0ddbdc31" && (<>
                  <p>📧 <strong>Email:</strong> {order.email}</p>
                  <p>📞 <strong>Phone:</strong> {order.phone}</p>
                  <p>💰 <strong>Total Price:</strong> Tsh {order.totalPrice.toLocaleString()}</p>
                  </>)}
                  <p>📍 <strong>Address:</strong> {order.address}</p>    
                  <p>📏 <strong>Size:</strong> {order.size}</p>
                  <p>🔄 <strong>Transaction ID:</strong> {order.transactionId}</p>
                  <p>🗓️ <strong>Order Date:</strong> {new Date(order.orderDate).toLocaleString()}</p>
                </div>

                <div className="ordered-items">
                  <h3 className="items-title">🛒 Ordered Items:</h3>
                  <div className="items-list">
                    {filteredCartItems.map((item, index) => {
                      const imageUrl = getProductImage(item.image[0]?._key);

                      return (
                        <div key={index} className="ordered-item">
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt={item.name}
                              className="item-image"
                            />
                          )}
                          <div className="item-details">
                            <p className="item-name">{item.name}</p>
                            <p className="item-quantity">📦 {item.quantity} pcs</p>
                            <p className="item-price">💰 {item.price.toLocaleString()} Tsh</p>
                            <p className="item-price">Seller: {item.sellerName}</p>
                            <p className="item-price">{item.sellerPhone}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Buttons to mark as processed or shipped */}
                {userId === "657d60c2e11554ed0ddbdc31"?(
                <div className="order-actions">
                  <button
                    className="processed-btn"
                    onClick={() => handleProcessed(order._id)}
                    disabled={order.status === 'processed' || order.status === 'shipped'}
                  >
                    {order.status === 'processed' || order.status === 'shipped' ? 'Processed' : 'Mark as Processed'}
                  </button>

                  <button
                    className="shipped-btn"
                    onClick={() => handleShipped(order._id)}
                    disabled={order.status === 'shipped'}
                  >
                    {order.status === 'shipped' ? 'Shipped' : 'Mark as Shipped'}
                  </button>
                </div>):(<p style={{color:'blue', alignItems:'center'}}><strong>Submit the item/s</strong></p>)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OrdersList;