import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MobileTraderbrand from './MobileTraderbrand';
import { Link } from 'react-router-dom';
import { client, urlFor } from '../sanity/sanityClient';
import './TraderBrand.css';

const TraderBrand = () => {
  const { userbrandId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);

      try {
        // Fetch all products without filtering by userbrandId
        const query = `*[_type in [ "clothingandfashion", "electronics", "arts", "fashion", "salon", "grocery", "beauty", "crops",
         "toys", "sports", "kitchen", "hotels", "homeandfurniture", "hardware", "books", "estate", "foodandbeverages",
          "homeandfurnitures", "vehicles", "jewelryandaccessories", "medical"]] `;

        const result = await client.fetch(query);
        
        if (userbrandId === "657d60c2e11554ed0ddbdc31") {
          setProducts(result);
        } else {
          const filteredProducts = result.filter((product) => product.user === userbrandId);
          setProducts(filteredProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [userbrandId]);

  const deleteProduct = async (productId) => {
    try {
      await client.delete(productId);
      setProducts(products.filter((product) => product._id !== productId));
      alert('Product deleted successfully');
    } catch (error) {
      console.error("Error deleting product:", error);
      alert('Failed to delete product');
    }
  };

  if (loading) return <p className="noUserProducts">Loading products...</p>;

  return (
    <div className="product-list">
      {products.length > 0 ? (isMobile ? (
        <MobileTraderbrand products={products} deleteProduct={deleteProduct} userbrandId={userbrandId} />
      ) : (
        products.map((product) => (
          <div key={product._id} className="product-card">
            {product.image && (
              <img src={urlFor(product.image[0])} alt={product.name} className="product-image" />
            )}
            <h3 className="product-name">{product.name}</h3>
            <p className="product-price">Tsh {product.price}</p>
            <p className="product-user">{product.user}</p>
            {product.geolocation && <p>product.location</p>}
            {/* Only show delete button if the current userbrandId matches the product's user */}
            {userbrandId === product.user ||userbrandId === "657d60c2e11554ed0ddbdc31"? (
              <button
                onClick={() => deleteProduct(product._id)}
                className="delete-button"
              >
                Delete
              </button>
            ) : (
              <Link to={`/chat/${product.user}`}
              style={{
                  padding: '8px 12px',
                  backgroundColor: '#28a745',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  textDecoration: 'none'
                }}
              >Message</Link>
            )}
          </div>
        ))
      )) : (
        <p className="noUserProducts">No products found for this user.</p>
      )}
    </div>
  );
};

export default TraderBrand;