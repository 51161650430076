import React, { useRef, useEffect, useState} from 'react';
import { AiOutlineMinus, AiOutlineShopping, AiOutlinePlus, AiOutlineLeft } from 'react-icons/ai';
import { TiDeleteOutline } from 'react-icons/ti';
import { useStateContext } from '../context/StateContext';
import { urlFor } from '../sanity/sanityClient';
import {
  ordersubmit} from "../utils/APIRoutes";


const Cart = () => {
  const cartRef = useRef();
  const {
    totalPrice,
    totalQuantities,
    cartItems,
    setShowCart,
    toggleCartItemQuantity,
    onRemove,
  } = useStateContext();

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    email: '',
    phone: '',
    size:'',
    transactionId: '',
  });

  const sellerPaymentNumber = "7890"; // Example seller payment number

  useEffect(() => {
  if (showForm && cartRef.current) {
    cartRef.current.scrollTo({
      top: cartRef.current.scrollHeight,
      behavior: "smooth",
    });
  }
}, [showForm])

  const handleCheckout = () => {
    setShowForm(true);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...formData,
      cartItems,
      
    };

    try {
      const response = await fetch(ordersubmit, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Thank you for shopping with us!\n You will be notified about shipping cost.');
        setShowForm(false);
        setShowCart(false);
      } else {
        alert('Failed to submit order. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };


  return (
    <div className="cart-modal-overlay" ref={cartRef}
    >
      <div className="cart-modal-content" >
        <button
          type="button"
          className="cart-heading"
          onClick={() => setShowCart(false)}
        >
          <AiOutlineLeft />
          <span className="heading">Your Cart</span>
          <span className="cart-num-items">({totalQuantities} items)</span>
        </button>
        
        {cartItems.length < 1 && (
          <div className="empty-cart">
            <AiOutlineShopping size={150} />
            <h3>Your shopping bag is empty</h3>
            <button type="button" className="btn" onClick={() => setShowCart(false)}>
              Continue Shopping
            </button>
          </div>
        )}

        <div className="product-container">
          {cartItems.length >= 1 &&
            cartItems.map((item) => (
              <div className="product" key={item._id}>
                <img
                  src={urlFor(item?.image[0])}
                  className="cart-product-image"
                  alt="productname"
                />
                <div className="item-desc">
                  <div className="flex top">
                    <h5>{item.name}: TZS{item.price.toLocaleString()}/=</h5>
                    
                  </div>
                  <div className="flex bottom">
                    <div>
                      <p className="quantity-desc" style={{backgroundColor:'white'}}>
                        <span className="minus" onClick={() => toggleCartItemQuantity(item._id, 'dec')}>
                          <AiOutlineMinus />
                        </span>
                        <span className="num">{item.quantity}</span>
                        <span className="plus" onClick={() => toggleCartItemQuantity(item._id, 'inc')}>
                          <AiOutlinePlus />
                        </span>
                        <button
                      type="button"
                      className="remove-item"
                      onClick={() => onRemove(item)}
                    >
                      <TiDeleteOutline />
                    </button>
                      </p>

                    </div>
                    
                  </div>
                </div>
              </div>
            ))}
        </div>
         {cartItems.length >= 1 && !showForm && (
          <div className="cart-button" style={{ textAlign: 'center' }}>
            <h3>SubTotal: TZS{totalPrice.toLocaleString()}/=</h3>
            <div className="btn-container">
              <button type="button" className="btn" onClick={handleCheckout}>
                Pay Now
              </button>
            </div>
          </div>
        )}

                {showForm && (
          <form onSubmit={handleSubmit} className="checkout-form">
          <h4>SubTotal: TZS{totalPrice.toLocaleString()}/=</h4>
            <h3 style={{marginTop:'0'}}>Mpesa Lipa: {sellerPaymentNumber} Bridgenapp</h3>

            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="address"
              placeholder="Delivery Address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="size"
              placeholder="Enter size/color (optional)"
              value={formData.size}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="transactionId"
              placeholder="Paste transaction(muamala)"
              value={formData.transactionId}
              onChange={handleInputChange}
              required
            />

            <button type="submit" className="btn">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Cart;
