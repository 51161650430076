import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { urlFor } from "../sanity/sanityClient";
import "./OrdersList.css"; // Reusing the same CSS to match styles
import "./CategoryDisplayTwo.css"; // Adding a new CSS file for animations

const CategoryDisplayTwo = ({ categories, handleProductView }) => {
  return (
    <div className="ordered-items categories-grid-responsive"> 
          {categories &&
        categories.map((category) => (
          <div key={category._id} className="ordered-item category-hover"> 
            <Link to={`/category/${category.slug.current}`} 
            onClick={() => handleProductView(category._id, category._type)}
            style={{ textDecoration: "none", color: "black" }}>
              {category.image[0] && (
                <LazyLoadImage
                  src={urlFor(category.image[0])}
                  width={100} 
                  height={100} 
                  alt={category.name}
                  effect="blur"
                  className="item-image"
                />
              )}
            </Link>
           <div className="item-details" style={{ display: "flex", flexDirection: "column" }}>
        <p className="item-name">{category.name}</p>
        <p className="item-price">
          {category.details
            ? category.details.split(" ").slice(0, 8).join(" ") + (category.details.split(" ").length > 12 ? "..." : "")
            : ""}
        </p>
        <p className="item-price">
          Tzs: <strong>{category.price?.toLocaleString()}/=</strong>
        </p>
        {category.location && <p className="item-quantity">📍 {category.location}</p>}
      </div>

          </div>
        ))}
    </div>
  );
};

export default CategoryDisplayTwo;
