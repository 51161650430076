import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar, AiOutlineShopping } from 'react-icons/ai';
import { useStateContext } from '../context/StateContext';
import { client, urlFor } from '../sanity/sanityClient';
import ProductDisplay from './ProductDisplay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SlugProducts.css'; // Import custom CSS

const SlugProducts = ({ slugCategory, handleImageSelect }) => {
  const { onAdd } = useStateContext();
    const [randomFinalProducts, setRandomFinalProducts] = useState({});
  const isMobile=window.innerWidth<768; 

  const [productQuantities, setProductQuantities] = useState(() =>
    slugCategory.reduce((acc, product) => {
      acc[product._id] = 1;
      return acc;
    }, {})
  );

  const handleQuantityChange = (productId, type) => {
    setProductQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      if (type === 'increment') {
        updatedQuantities[productId] = (updatedQuantities[productId] || 0) + 1;
      } else if (type === 'decrement' && updatedQuantities[productId] > 1) {
        updatedQuantities[productId] -= 1;
      }
      return updatedQuantities;
    });
  };

  const handleAddToCart = (product) => {
    const focusedImage = product.image[product.currentImageIndex];
    const productWithFocusedImage = { ...product, image: [focusedImage] };
    const productQty = productQuantities[product._id] || 1;

    onAdd(productWithFocusedImage, productQty);

    toast.success(`${productQty} ${product.name} added to the cart.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  //const firstRowProducts = slugCategory.slice(0, 10);
  const remainingProducts = slugCategory.slice(4);

  const fetchOneRandomProductPerCategory = async () => {
  const categories = ["clothingandfashion", "electronics", "arts", "fashion", "salon", "grocery", "beauty", "crops",
         "toys", "sports", "kitchen", "hotels", "homeandfurniture", "hardware", "books", "estate", "foodandbeverages",
          "homeandfurnitures", "vehicles", "jewelryandaccessories", "medical"];
  const randomProducts = {};

  for (const category of categories) {
    // Fetch all products in the current category
    const products = await client.fetch(
     `*[_type in $categories]`,
          { categories }
    );

    // Randomly pick one product from the fetched products
    if (products.length > 0) {
      const randomIndex = Math.floor(Math.random() * products.length);
      randomProducts[category] = products[randomIndex];
    } else {
      randomProducts[category] = null; // Handle case where no products are found
    }
  }

  return randomProducts;
};

 useEffect(() => {
    const getRandomProducts = async () => {
      const products = await fetchOneRandomProductPerCategory();
      setRandomFinalProducts(products);
    };

    getRandomProducts();
  }, []); // Empty dependency array ensures this runs only on mount/re


  return (
    <div className="slug-products-container">
      <ToastContainer />

     {/* First row with responsive full-width overflow on small screens */}
       <div className="recommendationstwo">
              <div className="recommended-productstwo">
                {Object.entries(randomFinalProducts).map(([category, product]) => (
                <div key={category}>
                  <h3>{product.name}</h3>
                  {product ? (
                    <div>
                    
                  <Link to={`/category/${product.slug.current}`}
                      onClick={() => handleScrollToTop()}
                     >
                        {product.image[0] && (
                      <img
                        src={urlFor(product.image[0])}
                        width={250}
                        height={250}
                        alt={category.name}
                        effect="blur"
                        style={{ borderRadius: '30px', padding: '10px' }}
                      />
                    )}
                        </Link>
                      
                      <p>Tzs: {product.price?.toLocaleString()}/=</p>
                      {/* Render other product details as needed */}
                    </div>
                  ) : (
                    <p>No products found in this category.</p>
                  )}
                </div>
              ))}
              </div>
            </div>
     {isMobile?(<ProductDisplay products={slugCategory} handleImageSelect={handleImageSelect} handleAddToCart={handleAddToCart}/>):(

          <div className="remaining-products-grid">
        {remainingProducts.map((product) => (
          <div key={product._id} className="product-card">
            <h3 className="product-name">{product.name}</h3>
            <img
              src={urlFor(product.image[product.currentImageIndex])}
              alt={product.name}
              className="product-image"
            />
            <div className="thumbnail-container">
              {product.image.map((img, index) => (
                <img
                  key={index}
                  src={urlFor(img)}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${index === product.currentImageIndex ? 'active' : ''}`}
                  onClick={() => handleImageSelect(product._id, index)}
                />
              ))}
            </div>
            <p className="product-price">Price: TZS {product.price.toLocaleString()}/=</p>
            <p className="product-details">{product.details}</p>
            <div className="rating">
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiOutlineStar />
            </div>
            <div className="quantity-control">
              <span
                className="quantity-button"
                onClick={() => handleQuantityChange(product._id, 'decrement')}
              >
                <AiOutlineMinus />
              </span>
              <span className="quantity">{productQuantities[product._id] || 1}</span>
              <span
                className="quantity-button"
                onClick={() => handleQuantityChange(product._id, 'increment')}
              >
                <AiOutlinePlus />
              </span>
            </div>
            <button
              className="add-to-cart-button"
              onClick={() => handleAddToCart(product)}
            >
              Add to <AiOutlineShopping />
            </button>
          </div>
        ))}
      </div>
      )}
  
      
    </div>
  );
};

export default SlugProducts;