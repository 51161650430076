import React from 'react';


const SearchContact = ({ searchResults, changeCurrentChat,sortedContacts, currentSelected}) => {
  

  return (

      <div className="contacts" >
          {searchResults.length>0? (searchResults.map((contact, index) => {
            return (
              <div
                key={contact._id}
                className={`contact ${
                  index === currentSelected ? "selected" : ""
                }`}
                onClick={() => changeCurrentChat(index, contact)}
              >
                <div className="avatar">
                   {contact.avatarImage.startsWith ("https://") || contact.avatarImage.includes("https") ? (
                              <img
                                src={contact.avatarImage} // URL imag
                                alt={`${contact.username}'s avatar`}
                              />
                            ) : (
                              <img
                                src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                                alt={`${contact.username}'s avatar`}
                              />
                            )}
                  <b>
                    <p style={{ margin: "0" }}>{contact.username}</p>
                  </b>
                </div>
              </div>
            );
            }
        )):(<p>No results found {sortedContacts.length}!</p>)}
      </div>
   
  );
};

export default SearchContact;
