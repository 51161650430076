import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { client} from '../sanity/sanityClient';
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "@emotion/styled";
import { allusersroute,contactbymessages, host, updatefcmtoken } from "../utils/APIRoutes";
import { getCurrentToken } from '../firebase';
import ChatContainer from "../components/ChatContainer";
import Contacts from "../components/Contacts";
import CategorySwitch from "../components/CategorySwitch";
import Categories from "../components/Categories";
import UploadForm from "../components/UploadForm";
import Scraper from "../components/Scraper";
import DisplayUsers from "../components/DisplayUsers";


export default function Chat() {
  const navigate = useNavigate();
  const { categoryName, userId } = useParams();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [contactList, setContactList]=useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query]=useState([ "grocery", "beauty", "clothingandfashion", "electronics", "jewelryandaccessories", "fashion"]);
  const [categories, setCategories] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [listSet, setListSet]=useState(true);
  const [categoryType, setCategoryType] = useState(true);
  const [showContacts, setShowContacts] = useState(false);
  const [traderProducts, setTraderProducts]=useState([]);
  const [isTrader, setIsTrader]=useState(false);
  const [nearBy, setNearBy]=useState(null);
  const [message, setMessage]=useState("Welcome to BridgenApp");

  useEffect(() => {
  // Fetch user data from local storage
  async function fetchData() {
    const userData = localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY);
    if (userData) {
      setCurrentUser(JSON.parse(userData));
    }
  }

  fetchData();
}, []);


  const updateFCMToken = async (userId, token) => {
    try {
      await axios.post(updatefcmtoken, { userId, token }); // Replace with your actual API endpoint
    } catch (error) {
      console.error('Error updating FCM token:', error);
    }
  };

  useEffect(() => {
    // Connect to socket.io and add user on component mount
    if (currentUser) {
      socket.current = io(host);
      socket.current.emit('add-user', currentUser._id);

      // Update FCM token on component mount
      getCurrentToken()
        .then((currentToken) => {
          if (currentToken) {
            updateFCMToken(currentUser._id, currentToken);
          }
        })
        .catch((error) => {
          console.error('Error fetching FCM token:', error);
        });
    }

    return () => {
      // Cleanup socket connection on component unmount
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [currentUser]);

  useEffect(() => {
  async function fetchContacts() {
    if (currentUser) {
      if (currentUser.isGoogleUser || currentUser.isAvatarImageSet) {
        try {
          const data = await axios.get(`${allusersroute}/${currentUser._id}`);
          setContacts(data.data);
          
        } catch (error) {
          console.error("Error fetching contacts:", error.message);
        }
      } else {
        navigate("/setAvatar");
      }
    }
  }

  fetchContacts();
}, [currentUser, navigate]);


 useEffect(() => {
  async function fetchContactsList() {
    if (currentUser) {
      if (currentUser.isGoogleUser || currentUser.isAvatarImageSet) {
        try {
          const data = await axios.get(`${contactbymessages}/${currentUser._id}`);
          setContactList(data.data);
        } catch (error) {
          console.error("Error fetching contacts:", error.message);
        }
      } else {
        navigate("/setAvatar");
      }
    }
  }

  fetchContactsList();
}, [currentUser, navigate]);

const updateContactMessaged=async()=>{
    try {
            const data = await axios.get(`${contactbymessages}/${currentUser._id}`);

            setContactList(data.data);
          } catch (error) {
            console.error("Error fetching contacts:", error.message);
            // Handle the error here
          }

   }

   const changeCategory = () => {
    // Toggle category type
    setCategoryType(!categoryType);
  };

useEffect(() => {
  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      let data;

      if (nearBy) {
        // Fetch categories based on geolocation with limit
        const { latitude, longitude } = nearBy;
        const geoQuery = `
          *[_type in $activeQuery && geo::distance(geolocation, $currentLocation) < $distance] | order(_updatedAt desc) [0...40]`;
        const params = {
          activeQuery: categoryName ? [categoryName] : query,
          currentLocation: { lat: latitude, lng: longitude },
          distance: 1000, // Adjust the radius (in meters)
        };
        data = await client.fetch(geoQuery, params);
      } else { 
        // Default logic with randomization and limit
        const activeQuery = categoryName ? [categoryName] : query;
        const productQuery = `*[_type in $activeQuery] | order(_updatedAt desc) [0...$limit]`;
        data = await client.fetch(productQuery, { 
          activeQuery,
          limit: 40 // Set maximum items to fetch
        });
      }

      // Randomize the results client-side if you want true randomness
      const shuffledData = [...data].sort(() => Math.random() - 0.5);
      
      // If you want exactly 40 items (or fewer if there aren't enough)
      const finalData = shuffledData.slice(0, 40);
      
      setCategories(finalData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  fetchCategories();
}, [categoryName, query, nearBy]);

const handleNearbyClick = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setNearBy({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.error("Error getting geolocation:", error);
        alert("Unable to fetch your location. Please allow location access.");
      }
    );
  } else {
    alert("Geolocation is not supported by your browser.");
  }
};

useEffect(() => {
  console.log("userId:", userId, "contacts:", contacts); // Debugging
  if (userId && contacts.length > 0) {
    const matchedContact = contacts.find((contact) => contact._id === userId);
    if (matchedContact) {
      setCurrentChat(matchedContact);
      setMessage("");
    } else {
      console.error("No contact found with the provided userId.");
      setCurrentChat(undefined);
      setMessage("You clicked your own item!");
    }
  } else if (userId && contacts.length === 0) {
    setMessage("unregistered");
  } else {
    setMessage("Welcome to BridgenApp");
  }
}, [userId, contacts]);

  const handleChatChange = (chat) => {
    setCurrentChat(chat); // Set currentChat when a contact is clicked
  };


  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleProductDeselect = () => {
    setSelectedProduct(null);
  };

const handleBack=()=>{
    setCurrentChat(undefined);
  }
  

  const showCntcts=()=>{
    setShowContacts(!showContacts);
  }

  const fetchTraderProducts=async(traderId)=>{
    setIsTrader(!isTrader);
      try {
        // Fetch all products without filtering by userbrandId
        const query = `*[_type in [ "clothingandfashion", "electronics", "arts", "fashion", "salon", "grocery", "beauty", "crops",
         "toys", "sports", "kitchen", "hotels", "homeandfurniture", "hardware", "books", "estate", "foodandbeverages",
          "homeandfurnitures", "vehicles", "jewelryandaccessories", "medical"]] `;

        const result = await client.fetch(query);
        
        // Filter products on the client side based on userId
       
        const filteredProducts = result.filter((product) => product.user === traderId);
        setTraderProducts(filteredProducts);
        } catch (error) {
        console.error("Error fetching products:", error);
      } 
  }
  return (
    <>
      <Container currentChat={currentChat}>
  
        <div className="toLet" style={{width:"101%", height:"40px", top:'0rem'}}>
        <DisplayUsers setListSet={setListSet} fetchTraderProducts={fetchTraderProducts} contactList={contactList} changeChat={handleChatChange} showCntcts={showCntcts} currentChat={currentChat}/>
        </div> 
        <div>
          <div className="container">
            <div className='contacts-container'>{showContacts && (
              listSet ? (
                <CategorySwitch showCntcts={showCntcts}/>
              ) : (
                <Contacts contacts={contacts} changeChat={handleChatChange} setListSet={setListSet} showCntcts={showCntcts}/>
              )
            )}</div>

            {!currentUser || currentChat === undefined ? (
              categoryType ? (
                <Categories
                  categories={categories}
                  currentUser={currentUser}
                  isLoading={isLoading}
                  changeCategory={changeCategory}
                  showCntcts={showCntcts}
                  handleNearbyClick={handleNearbyClick}
                  fetchTraderProducts={fetchTraderProducts}
                  nearBy={nearBy}
                  isTrader={isTrader}
                  traderProducts={traderProducts}
                  message={message}
                />
              ) : (
                <UploadForm />
              )
            ) : (
              <ChatContainer
                currentChat={currentChat}
                socket={socket}
                selectedProduct={selectedProduct}
                productDeselect={handleProductDeselect}
                handleBack={handleBack}
                fetchMessaged={updateContactMessaged}
                fetchTraderProducts={fetchTraderProducts}
              />
            )}
            {currentChat && <Scraper onSelectedProduct={handleProductSelect} 
            categories={categories} isLoading={isLoading} isTrader={isTrader}
              traderProducts={traderProducts}/>}
          </div>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  gap: 1rem;
  padding-left:10px;
  align-items: center;
  background-color: white;

  .container {
    position: relative;
    height: calc(100vh - 10vh); /* Adjusted height to leave space for the footer */
    width: 100vw;
    background-color: white;
    display: ${(props) => (props.currentChat ? "grid" : "flex")};
    grid-template-columns: ${(props) => (props.currentChat ? "0% 50% 50%" : "none")};
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: ${(props) => (props.currentChat ? "0% 50% 50%" : "none")};
    }
  }

  .contacts-container {
    position: ${(props) => (props.currentChat ? "relative" : "absolute")};
    z-index: ${(props) => (props.currentChat ? "auto" : "2")};
    top: 0;
    left: 0;
    width: 30%;
  }
`;

