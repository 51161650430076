import React from "react";
import styled from "styled-components";

export default function PrivacyPolicy() {
  return (
    <PolicyContainer>
      <div className="content">
        <h4>Bridgenapp Privacy Policy</h4>
        <p>
          At Bridgenapp, we value your privacy and are committed to protecting your personal data. 
          This Privacy Policy outlines how we collect, use, and store your information.
        </p>
        <p>
          <strong>What Data We Collect:</strong> We collect basic profile information (name, email, profile picture) 
          through Google login to create your account and personalize your experience.
        </p>
        <p>
          <strong>How We Use Your Data:</strong> The data is used for authentication purposes only, including profile creation 
          and enabling features such as personalized recommendations.
        </p>
        <p>
          <strong>Data Security:</strong> All data is securely stored using encryption and accessed only by authorized personnel.
        </p>
        <p>
          <strong>Data Sharing:</strong> Your data will never be sold or shared with third parties except as required by law.
        </p>
        <p>
          <strong>Your Rights:</strong> You can request deletion of your data at any time by contacting us directly through 
          chatting with the Support Team in the App.
        </p>
      </div>
    </PolicyContainer>
  );
}

const PolicyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background-color: #f9f9f9;
  color: #333;

  .content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    font-family: 'Arial', sans-serif;

    h4 {
      text-align: center;
      margin-bottom: 1.5rem;
      color: #4a90e2;
      font-size: 1.8rem;
    }

    p {
      line-height: 1.6;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    strong {
      color: #4a90e2;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;

    .content {
      padding: 1.5rem;

      h3 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      h4 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }
`;
