import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { MdOutlineMessage } from 'react-icons/md';
import styled from "styled-components";
import { onlineUsersRoute } from "../utils/APIRoutes";

export default function ContactsMessaged({ setListSet, contactList, changeChat, showCntcts, currentChat, fetchTraderProducts }) {
  const navigate = useNavigate();
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [contactLists, setContactLists] = useState([]);
  const [currentUserName, setCurrentUserName] = useState(undefined);
  const [currentUserImage, setCurrentUserImage] = useState(undefined);
  const [currentUserId, setCurrentUSerId] = useState(undefined);
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const [closeButton, setCloseButton] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const data = await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );

      if (data) {
        setCurrentUserName(data.username);
        setCurrentUserImage(data.avatarImage);
        setCurrentUSerId(data._id);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchOnlineUsers = async () => {
      try {
        const onlineUsersData = await axios.get(onlineUsersRoute);
        setOnlineUsers(onlineUsersData.data);
      } catch (err) {
        console.error("Error fetching online users:", err.message);
      }
    };

    fetchOnlineUsers()
  }, []);

  useEffect(() => {
    if (contactList.length && onlineUsers.length) {
      const updatedContacts = contactList.map((contact) => ({
        ...contact,
        isOnline: onlineUsers.some((user) => user.userId === contact._id),
      }));
      setContactLists(updatedContacts);
    }
  }, [contactList, onlineUsers]);

  const handleList = () => {
    showCntcts();
    setListSet(false);
    setCloseButton(!closeButton);
  }

  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
  };

  return (
    <>
      {currentUserImage && currentUserImage && (
        <Container>
          <div className="current-user" hidden>
            <div className="avatar" style={{ display: 'flex', height: '35px', marginTop: 0 }}>
              <Link to={`/traderbrand/${currentUserId}`}>
                {currentUserImage.startsWith("https://") || currentUserImage.includes("https") ? (
                  <img
                    src={currentUserImage} // URL imag
                    alt={`${currentUserName}'s avatar`}
                  />
                ) : (
                  <img
                    src={`data:image/svg+xml;base64,${currentUserImage}`}
                    alt={`${currentUserName}'s avatar`}
                  />
                )}
              </Link>
            </div>
            <div className="username">
              {currentChat === undefined ? (
                <button onClick={handleList}>
                  <p style={{ fontSize: "12px", display: 'flex', height: "1px", marginTop: "0px" }}><strong>Connect </strong><MdOutlineMessage /></p>
                </button>
              ) : (
                <button onClick={() => fetchTraderProducts(currentUserId)}>{currentUserName.split(" ")[0]}</button>
              )}
            </div>
          </div>
          <div className="contacts">
            {contactLists.length > 0 &&
              contactLists.map((contact, index) => {
                return (
                  <div
                    key={contact._id}
                    className={`contact ${index === currentSelected ? "selected" : ""}`}
                    onClick={() => changeCurrentChat(index, contact)}
                  >
                    <div className="avatar">
                      {contact.isOnline && <span className="online-dot"><p style={{ fontSize: '8px', color: 'green', margin: "0" }}>online</p></span>}
                      {contact.avatarImage ? (
                        <>
                          {contact.avatarImage.startsWith("https://") || contact.avatarImage.includes("https") ? (
                            <img
                              src={contact.avatarImage}
                              alt={`${contact.username}'s avatar`}
                            />
                          ) : (
                            <img
                              src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                              alt={`${contact.username}'s avatar`}
                            />
                          )}
                        </>
                      ) : (
                        <img src="/default-avatar.png" alt="Default avatar" />
                      )}
                      <div className="username-overlay">
                        <b>
                          <p style={{ margin: 0 }}>{contact.username.split(" ")[0]}</p>
                        </b>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Container>
      )}

      {!currentUserName && (
        <UnauthenticatedChatButton>
          <button onClick={() => navigate('/login')}>
            Chat <MdOutlineMessage />
          </button>
          <Link to='/unboxing' style={{ textDecoration: 'none', fontSize: '12px' }}>Surprise Unboxing</Link>
          <Link to="/privacy-policy" style={{ textDecoration: 'none', padding: '20px', fontSize: '12px' }}>
            Privacy Policy
          </Link>
        </UnauthenticatedChatButton>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  grid-template-rows: 10% 75% 15%;
  overflow: hidden;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 0rem;
  .brand {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
    justify-content: center;
    img {
      height: 2rem;
    }
    h3 {
      color: white;
    }
  }
  .contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    z-index: 1;
    gap: 0;
    &::-webkit-scrollbar {
      width: none;
      display: none;
      &-thumb {
        background-color: #f0f0f0;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      background-color: #ffffff34;
      min-height: 3rem;
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      flex-direction: row;
      gap: 0;
      align-items: center;
      transition: 0.5s ease-in-out;
      .avatar {
        position: relative;
        img {
          height: 2rem;
        }
        .username-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
          color: white;
          text-align: center;
          padding: 2px;
          font-size: 12px;
        }
      }
      .username {
        h4 {
          color: black;
        }
      }
    }
  }

  .current-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    z-index: 10;
    .avatar {
      img {
        height: 3rem;
        max-inline-size: 100%;
      }
    }
    .username {
      h3 {
        color: black;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
`;

const UnauthenticatedChatButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0rem;
  button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    &:hover {
      background-color: #007bff;
    }
  }
`;